<template>
    <div>
        <seamless-access-button :redirect="redirectTo('satosa')" class="mb-4" />
        <google-oauth-btn :redirect="redirectTo('google')">Sign up with Google</google-oauth-btn>
        <div class="d-flex align-center mt-5 mb-2">
            <v-divider />
            <span class="caption px-2">OR</span>
            <v-divider />
        </div>
        Please provide your details below to set up your account:
        <v-form ref="form" v-model="valid" class="mt-4">
            <v-text-field
                outlined
                dense
                class="first-name-field"
                v-model="firstName"
                :counter="50"
                label="First Name"
                :rules="[rules.nonEmpty]"
                required></v-text-field>
            <v-text-field
                outlined
                dense
                class="last-name-field"
                v-model="lastName"
                :counter="50"
                label="Last Name"
                :rules="[rules.nonEmpty]"
                required></v-text-field>
            <v-text-field
                outlined
                dense
                class="password-first-field"
                v-model="password"
                :counter="50"
                label="Password"
                required
                type="password"
                :rules="
                    password.length
                        ? [
                              rules.passwordHasNoFirstSpecialCharacter,
                              rules.passwordAllowedCharacters,
                              rules.passwordLength,
                              rules.passwordHasUppercase,
                              rules.passwordHasLowercase,
                              rules.passwordHasNumber,
                              rules.passwordHasSpecialCharacter
                          ]
                        : [rules.nonEmpty]
                "></v-text-field>
            <v-text-field
                outlined
                dense
                class="password-second-field"
                v-model="password2"
                :counter="50"
                label="Verify Password"
                required
                type="password"
                :rules="[rules.passwordMatch, rules.passwordLength]"></v-text-field>
            <v-text-field outlined dense :value="signupUserDetails.email" label="E-mail" required readonly></v-text-field>

            <v-btn block color="primary" :disabled="!valid" @click="signIn()">Sign in</v-btn>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
const SeamlessAccessButton = () => import('@/components/SeamlessAccessButton.vue')
const GoogleOauthBtn = () => import('@/components/GoogleOauthBtn.vue')

export default {
    name: 'InvitationHome',
    components: { SeamlessAccessButton, GoogleOauthBtn },
    data() {
        return {
            valid: false,
            password: '',
            password2: '',
            firstName: '',
            lastName: '',
            rules: {
                passwordMatch: p => this.password === p || 'Passwords do not match.',
                passwordAllowedCharacters: p =>
                    p.search(/[^a-zA-Z0-9!#$%^&().\-=_+:]/) <= -1 ||
                    'Password contains a not-allowed character. Allowed characters: a-z A-Z 0-9 ! # $ % ^ & ( ) . - = _ + :',
                passwordHasUppercase: p => p.search(/[A-Z]/) > -1 || 'Password must contain at least one uppercase letter.',
                passwordHasLowercase: p => p.search(/[a-z]/) > -1 || 'Password must contain at least one lowercase letter.',
                passwordHasNumber: p => p.search(/[0-9]/) > -1 || 'Password must contain at least one number.',
                passwordHasSpecialCharacter: p => p.search(/[!#$%^&().\-=_+:]/) > -1 || 'Password must contain at least one special character.',
                passwordLength: p => p.length >= 9 || 'Password needs to be at least 9 characters.',
                passwordHasNoFirstSpecialCharacter: p => p[0].search(/[!#$%^&().\-=_+:]/) === -1 || 'Password cannot start with special character.',
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            }
        }
    },
    computed: {
        ...mapState('userStore', ['signupUserDetails'])
    },
    methods: {
        signIn() {
            this.$store.dispatch('userStore/setSignupUserDetails', {
                email: this.signupUserDetails.email,
                password: this.password,
                firstName: this.firstName,
                lastName: this.lastName
            })
            this.$router.push({
                name: 'invitation-password',
                params: { invid: this.$route.params.invid }
            })
        },
        redirectTo(provider) {
            return this.$router.resolve({ name: 'invitation-provider', params: { invid: this.$route.params.invid, provider } }).href
        }
    }
}
</script>
